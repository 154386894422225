<template>
  <div class="ladies project-component">
    <div class="fullsection">
      <p>
        After the success of my 24 days of Javascriptmas challenge the preceding year, I wanted to tackle a new
        challenge. As women students in STEM fields often suffer from underrepresentafion in the industry, I wanted to
        showcase 24 at once, from various backgrounds, ages, ethnicities, and nationalities.
      </p>
      <br />
    </div>
    <div class="full-section half-section-media">
      <img src="@/assets/projects/women/album/1.png" alt="" />
    </div>
    <div class="half-section half-section-media ">
      <img src="@/assets/projects/women/album/2.png" alt="" />
    </div>
    <div class="half-section">
      <p>
        I organized, filmed and edited 24 interviews with women in STEM I contacted through various ways, Linkedin, Instagram, Ladies of code and personal relations. Most of them where working in the CompSci field but also ranging from
        Mechanical engineering to Astrophysics.
      </p>
    </div>
    <div class="half-section">
      <p>
        This was a great experience that taught me that STEM suffers from a lot of stigma that scares girls wanting to
        go into it, but this shouldn’t be an obstacle and more motivation for us to see opportunity in our rareness.
      </p>
    </div>
    <div class="half-section half-section-media">
      <img src="@/assets/projects/women/album/3.png" alt="" />
    </div>

    <div class="lesson-box">
      <h2>What did I learn ?</h2>
      <br />
      <p>
        The most difficult part was project management, scheduling meetings with women accross the globe and setting up
        a good sound and video quality. It gave me a lot of confidence and drive to share my love for tech with as many
        diverse people as possible
      </p>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Women",
};
</script>
<style scoped>

</style>